import Image from 'next/image'
import statusPageScreenshot from '../../public/screenshot-status-page.png'

export default function StatusPageSection() {
    return (
        <>
            {/* Feature section with screenshot */}
            <div className="relative bg-gray-50 pt-16 sm:pt-24 lg:pt-32">
                <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                    <div>
                        {/* <h2 className="text-lg font-semibold text-cyan-600">Tell your customers</h2> */}
                        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                            Status Pages
                        </p>
                        <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">
                            Let your customers see your website&apos;s status or keep it displayed for your team in the office.
                        </p>
                    </div>
                    <div className="mt-12 -mb-10 sm:-mb-24 lg:-mb-80">
                        {/* <img
                            // className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"
                            // src="https://tailwindui.com/img/component-images/green-project-app-screenshot.jpg"
                            src="/screenshot-status-page.png"
                            alt=""
                        /> */}

                        <Image
                            src={statusPageScreenshot}
                            width={2610}
                            height={1874}
                            alt="Status Page"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
