import Head from 'next/head'
import Header from '../components/Header'
import HeadingWithCta from '../components/landing/HeadingWithCta'
import FeaturesSection from '../components/landing/FeaturesSection'
import Footer from '../components/Footer'
import CtaBottom from '../components/landing/CtaBottom'
import PricingSection from '../components/landing/PricingSection'
import StatusPageSection from '../components/landing/StatusPageSection'

export default function LandingPage() {
    return (
        <div className="bg-white">
            <Head>
                <title>
                    Uptime&nbsp;Monitor - Website Uptime Monitoring Done Right.
                </title>
                <meta name="description" content="Uptime Monitor is your free uptime monitoring solution, ensuring the continuous availability of your websites and servers. Experience our fast and reliable service today and stay ahead of potential downtimes." />
                {/* <meta name="description" content="Free website and server uptime monitoring tool. Status pages included." /> */}
                <link rel='canonical' href='https://uptime-monitor.io/' />
            </Head>
            <div className="relative overflow-hidden">
                <Header />

                <main>
                    <HeadingWithCta />

                    <FeaturesSection />
                    <StatusPageSection />
                    <PricingSection />





                    {/* <TestimonialsSection />

                    <BlogSection />

                    <CtaSection /> */}
                    {/* <CtaBottom /> */}
                </main>

                <Footer />
            </div>
        </div>
    )
}
